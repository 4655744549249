import React, { useContext } from "react";
import StatusBadge from "../common/StatusBadge";
import UserItem from "../users/UserItem";
import moment from "moment";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { CambioEstimacionVersionesContext } from "../../context/CambioEstimacionVersionsContext";
import useProyecto from "../../hooks/useProyecto";

const CambioEstimacionVersionCard = ({ cambioestimacionversion }) => {
  const { resendNotifications } = useContext(CambioEstimacionVersionesContext);
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { user } = useContext(AuthContext);
  const { idProyecto } = useProyecto();

  const confirmResend = () => {
    const { idCambioEstimacionVersion } = cambioestimacionversion;
    modalComponent(
      "¿Reenviar Avisos?",
      <div>
        <p>
          Se volverán a enviar los correos de notificación a todos los usuarios
          del proyecto sobre este cambio en la versión de la estimación.
        </p>
        <div className="row">
          <div className="col-6">
            <button
              type="button"
              onClick={clearModal}
              className="btn w-100 text-muted"
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button
              className="btn w-100 btn-primary"
              onClick={() => resendNotifications(idProyecto, idCambioEstimacionVersion, clearModal)}
            >
              <i className="fa fa-envelope" /> Reenviar
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      key={cambioestimacionversion.idCambioEstimacionVersion}
      className="card px-2 pt-2 border mb-3"
    >
      <div className="row mb-3 align-items-center">
        <div className="col-6 col-md-8 ps-1">
          <UserItem size="sm" user={cambioestimacionversion.Usuario} />
          {(user.idUsuario === cambioestimacionversion.idUsuario ||
            user.permiso === "admin") && (
            <button onClick={confirmResend} className="btn btn-sm btn-link">
              <i className="fa fa-envelope me-1" /> Reenviar avisos
            </button>
          )}
        </div>
        <div className="col-6 col-md-4 pe-3 text-end">
          <span className="small d-block">
            {moment(cambioestimacionversion.createdAt).format("DD MMM YYYY")}
          </span>
          <span className="small d-block">
            <i className="far fa-clock" />{" "}
            {moment(cambioestimacionversion.createdAt).format("HH:mm")}
          </span>
        </div>
      </div>
      <p className="bg-light border card p-3">
        {cambioestimacionversion.comentario}
      </p>
      <table className="table border small">
        <tbody>
          <tr>
            <td className="bg-light bold border">De:</td>
            <td className="border">
              <StatusBadge
                small
                text={cambioestimacionversion.estado_anterior}
              />
            </td>
          </tr>
          <tr>
            <td className="bg-light bold border">A:</td>
            <td className="border">
              <StatusBadge small text={cambioestimacionversion.estado_nuevo} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default CambioEstimacionVersionCard;
