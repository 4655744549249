import React, { useContext, useEffect } from "react";
import UploadEstimacionVersion from "../components/preciario/UploadEstimacionVersion";
import UploadPreciarioForm from "../components/preciario/UploadPreciarioForm";
import SingleCambioEstimacionVersion from "./SingleCambioEstimacionVersion";
import ConceptoHistorial from "../components/finanzas/ConceptoHistorial";
import SingleConceptoEstimaciones from "./SingleConceptoEstimaciones";
import SingleEstimacionVersion from "./SingleEstimacionVersion";
import SingleConceptoGenerador from "./SingleConceptoGenerador";
import { ProyectosContext } from "../context/ProyectosContext";
import Contratos from "../components/finanzas/Contratos";
import EstimacionVersiones from "./EstimacionVersiones";
import { MenuContext } from "../context/MenuContext";
import { Route, Routes } from "react-router-dom";
import SingleContrato from "./SingleContrato";
import UploadEstimacionForm from "../components/preciario/UploadEstimacionForm";

const Finanzas = ({ idProyecto }) => {
  const { setSelected } = useContext(MenuContext);
  const { proyecto, getSingleProyecto } = useContext(ProyectosContext);

  useEffect(() => {
    getSingleProyecto(idProyecto);
    setSelected({ title: "finanzas" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    if (proyecto && proyecto !== null) {
      return (
        <Routes>
          <Route element={<Contratos />} path="/" />
          <Route element={<SingleContrato />} path="/:idContrato" />
          <Route
            element={<UploadPreciarioForm />}
            path="/:idContrato/preciario"
          />
          <Route
            element={<UploadEstimacionForm />}
            path="/:idContrato/estimacion/:idEstimacion/subir"
          />
          {/* Estimaciones Versiones */}
          <Route
            element={<EstimacionVersiones />}
            path="/:idContrato/estimacion/:idEstimacion/versiones"
          />
          <Route
            element={<UploadEstimacionVersion />}
            path="/:idContrato/estimacion/:idEstimacion/versiones/subir"
          />
          <Route
            element={<SingleEstimacionVersion />}
            path="/:idContrato/estimacion/:idEstimacion/versiones/:idEstimacionVersion"
          />
           <Route
            element={<SingleCambioEstimacionVersion />}
            path="/:idContrato/estimacion/:idEstimacion/versiones/:idEstimacionVersion/cambio/:idCambioEstimacionVersion"
          />
          {/* Single Concepto */}
          <Route
            element={<ConceptoHistorial />}
            path="/conceptos/:idConcepto"
          />
          <Route
            element={<SingleConceptoEstimaciones />}
            path="/:idContrato/concepto/:idConcepto"
          />
          <Route
            element={<SingleConceptoGenerador />}
            path="/:idContrato/generador/:idConcepto/estimacion/:idEstimacion"
          />
        </Routes>
      );
    }
  };

  return (
    <>
      {renderContent()}
    </>
  );
};

export default Finanzas;
