import React from "react";
import { formatMonto, getValue } from "../../utils";

const Montos = ({ autorizado, ejecutado, estimacion, extra, pagos }) => {
  function getAnticipo() {
    if (pagos && pagos !== null) {
      let anticipo = pagos.find((pago) =>
        String(pago.comentario).toLowerCase().includes("anticipo") || getValue(pago, "anticipo", "boolean")
      );
      if (anticipo) return anticipo.monto;
    }
    return 0;
  }

  function getSumaPagos() {
    if (pagos && pagos !== null) {
      if (pagos.length > 1) {
        let montos = pagos.map((pago) => pago.monto);
        return montos.reduce((a, b) => parseFloat(a) + parseFloat(b));
      } else if (pagos.length === 1) {
        return pagos[0].monto;
      }
    }
    return 0;
  }

  function getPorcentajeAnticipo() {
    const anticipo = getAnticipo();
    return parseFloat((anticipo / autorizado) * 100).toFixed(2);
  }

  function getPagoSugerido() {
    let pago = estimacion.ejecutado;
    let total = getSumaPagos();
    let anticipo = getAnticipo();
    let restante = total - anticipo;
    if (restante < anticipo) {
      let amortizado = (pago * getPorcentajeAnticipo()) / 100;
      pago = amortizado;
    }
    return formatMonto(pago);
  }

  function renderMontoEstimacion() {
    if (estimacion && estimacion !== null) {
      const anticipo = getAnticipo();
      return (
        <>
          <div className="row py-2">
            <div className="col">Monto de estimacion #{estimacion.numero}</div>
            <div className="col text-end">
              {"$"}
              {formatMonto(estimacion.ejecutado)}
            </div>
          </div>
          {anticipo > 0 && (
            <div className="row py-2">
              <div className="col">
                Pago Sugerido ({getPagoSugerido()}% de Anticipo)
              </div>
              <div className="col text-end">
                {"$"}
                {getPagoSugerido()}
              </div>
            </div>
          )}
        </>
      );
    }
  }

  function renderMontoExtra() {
    if (extra && extra !== null) {
      return (
        <div className="row py-2">
          <div className="col">Monto ejecutado en extras</div>
          <div className="col text-end">
            {"$"}
            {formatMonto(extra)}
          </div>
        </div>
      );
    }
  }

  function renderAnticipo() {
    if (pagos && pagos !== null) {
      if (pagos.length > 0) {
        const anticipo = getAnticipo();
        if (anticipo > 0) {
          return (
            <>
              <div className="row border-bottom py-2">
                <div className="col px-0">Monto de Anticipo</div>
                <div className="col px-0 text-end">
                  {"$"}
                  {formatMonto(getAnticipo())}
                </div>
              </div>
              <div className="row border-bottom py-2">
                <div className="col px-0">Porcentaje de Anticipo</div>
                <div className="col px-0 text-end">
                  {getPorcentajeAnticipo()}
                  {"%"}
                </div>
              </div>
            </>
          );
        }
      }
    }
  }

  return (
    <div className="card p-3 shadow">
      <h4 className="h6 bold">Montos fijos de obra</h4>
      <div className="container-fluid mb-3">
        <div className="row py-2 border-top">
          <div className="col px-0">Contrato Autorizado</div>
          <div className="col px-0 text-end">
            {"$"}
            {formatMonto(autorizado)}
          </div>
        </div>
        {renderAnticipo()}
      </div>
      <h4 className="h6 bold">Montos variables de obra</h4>
      <div className="container-fluid">
        {renderMontoEstimacion()}
        {renderMontoExtra()}
        <div className="row py-2 border-top">
          <div className="col px-0">Ejecutado</div>
          <div className="col px-0 text-end">
            {"$"}
            {formatMonto(ejecutado)}
          </div>
        </div>
        <div className="row py-2 border-top">
          <div className="col px-0">Pagado</div>
          <div className="col px-0 text-end">
            {"$"}
            {formatMonto(getSumaPagos())}
          </div>
        </div>
        <div className="row py-2 border-top">
          <div className="col px-0">Por Ejecutar</div>
          <div className="col px-0 text-end">
            {"$"}
            {formatMonto(autorizado - ejecutado)}
          </div>
        </div>
        {/* <div className="row py-2 border-top">
          <div className="col px-0">Anticipo - Pagado</div>
          <div className="col px-0 text-end">
            {"$"}
            {formatMonto(ejecutado - getSumaPagos())}
          </div>
        </div> */}
        <div className="row py-2 border-top">
          <div className="col px-0">Ejecutado - Pagado</div>
          <div className="col px-0 text-end">
            {"$"}
            {formatMonto(ejecutado - getSumaPagos())}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Montos;
