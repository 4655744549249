import React, { useContext } from "react";
import { Link } from "react-router-dom";
import useProyecto from "../../hooks/useProyecto";
import usePreciario from "../../hooks/usePreciario";
import EstimacionForm from "../finanzas/EstimacionForm";
import { ModalContext } from "../../context/ModalContext";
import SingleEstimacionHeader from "./SingleEstimacionHeader";
import ConceptosEstimacion from "../conceptos/ConceptosEstimacion";
import { EstimacionesContext } from "../../context/EstimacionesContext";
import { CapacitorContext } from "../../context/CapacitorContext";
import ConceptosEstimacionMobile from "../conceptos/ConceptosEstimacionMobile";
import StatusBadge from "../common/StatusBadge";
import { getValue } from "../../utils";
import { AppConfigContext } from "../../context/AppConfigContext";

const SingleEstimacion = () => {
  const { estimacion, editEstimacion, selectEstimacion } =
    useContext(EstimacionesContext);
  const { modalComponent } = useContext(ModalContext);
  const { platform } = useContext(CapacitorContext);
  const appconfig = useContext(AppConfigContext);
  const { idContrato } = usePreciario();
  const { idProyecto } = useProyecto();

  const versionesURL = `/obra/${idProyecto}/finanzas/${idContrato}/estimacion/${estimacion.idEstimacion}/versiones`;
  const estimacionURL = `/obra/${idProyecto}/finanzas/${idContrato}/estimacion/${estimacion.idEstimacion}`;

  function handleCallback() {
    selectEstimacion(idProyecto, idContrato, estimacion.idEstimacion);
  }

  function handleEdit() {
    editEstimacion(estimacion);
    modalComponent(
      "Editar Estimacion",
      <EstimacionForm
        idProyecto={idProyecto}
        idContrato={idContrato}
        handleCallback={handleCallback}
      />
    );
  }

  function renderVersion() {
    if (estimacion.EstimacionVersion !== null) {
      const { idEstimacionVersion, estado, version } =
        estimacion.EstimacionVersion;
      return (
        <>
          {getValue(appconfig, "versions_enabled", "boolean") && (
            <div className="row mb-3 mt-4">
              <div className="col-6">
                <div className="align-items-center">
                  <h4 className="d-inline mb-0">
                    Versión #{version}
                    <Link
                      className="btn btn-sm btn-outline-dark border ms-2 hide-mobile"
                      to={`${versionesURL}/${idEstimacionVersion}`}
                    >
                      <i className="fa fa-eye me-1" /> Detalle
                    </Link>
                  </h4>
                  <StatusBadge text={estado} />
                </div>
              </div>
              <div className="col-6 text-end">
                <Link
                  className="btn btn-outline-dark border"
                  to={versionesURL}
                  key="versiones"
                >
                  <i className="fa fa-tasks me-1" />
                  Versiones
                </Link>
              </div>
            </div>
          )}
          {platform !== "web" ? (
            <div className="px-1">
              <ConceptosEstimacionMobile conceptos={estimacion.conceptos} />
            </div>
          ) : (
            <ConceptosEstimacion conceptos={estimacion.conceptos} />
          )}
          {!getValue(appconfig, "versions_enabled", "boolean") && (
            <Link to={`${estimacionURL}/subir`} className="btn btn-primary">
              <i className="fa fa-upload me-1" />
              Subir Estimacion
            </Link>
          )}
        </>
      );
    }
    if (getValue(appconfig, "versions_enabled", "boolean"))
      return (
        <div>
          <p>Esta estimación aún no tiene versiones cargadas en Arqvisor.</p>
          <Link to={`${versionesURL}/subir`} className="btn btn-primary">
            <i className="fa fa-upload me-1" />
            Subir Versión
          </Link>
        </div>
      );
  }

  return (
    <div>
      <div className="row mb-2 align-items-center">
        <div className="col-8">
          <h3 className="mb-0">Estimación #{estimacion.numero}</h3>
        </div>
        <div className="col-4 text-end">
          <button
            key="edit"
            onClick={handleEdit}
            className="btn btn-outline-primary"
          >
            <i className="fa fa-edit" />
            <span className="hide-mobile ms-1">Editar</span>
          </button>
        </div>
      </div>
      <SingleEstimacionHeader />
      {renderVersion()}
    </div>
  );
};

export default SingleEstimacion;
