import React, { useEffect, useState, useContext } from "react";
import { ProductsContext } from "../../context/ProductsContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import SearchableSelect from "../global/SearchableSelect";
import { getValue } from "../../utils";
import "./product.css";

const ProductForm = ({ idProducto, idProyecto, parentId, handleCancel }) => {
  const {
    product,
    saveProduct,
    createProduct,
    getSingleProduct,
    setPropertyProduct,
  } = useContext(ProductsContext);
  const { getAllProyectos, proyectos } = useContext(ProyectosContext);
  const [skuError, setSkuError] = useState(false);

  useEffect(() => {
    if (isNaN(parseInt(idProducto))) {
      createProduct();
    } else {
      getSingleProduct(idProducto, idProyecto);
    }
    getAllProyectos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProducto]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "sku") {
      const regex = /^[a-zA-Z0-9-]*$/;
      if (!regex.test(value)) {
        setSkuError(true);
      } else {
        setSkuError(false);
      }
    }
    setPropertyProduct(name, value);
  };

  const handleProjectChange = (selectedProject) => {
    handleChange({
      target: { name: "idProyecto", value: selectedProject.value },
    });
  };

  const displayProjectSelector = () => {
    if (!parentId && proyectos && proyectos.length > 0) {
      return (
        <div className="mb-4">
          <label>Proyecto</label>
          <SearchableSelect
            className="form-control bg-light py-2"
            items={proyectos}
            itemText={"nombre"}
            itemValue={"idProyecto"}
            modifier={handleProjectChange}
            value={product.idProyecto}
          />
        </div>
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveProduct(product);
  };

  return (
    <form onSubmit={handleSubmit} className="form product-form">
      <div className="row">
        <div className="col-12 col-md-6">
          <label>SKU:</label>
          <div className="mb-3">
            <input
              className={`form-control bg-light py-2 ${
                skuError ? "is-invalid" : ""
              }`}
              type="text"
              name="sku"
              onChange={handleChange}
              value={getValue(product, "sku")}
            />
            {skuError && (
              <div className="invalid-feedback">
                SKU solo debe contener letras, números y '-'
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-md-6">{displayProjectSelector()}</div>
      </div>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-12 col-md-6">
            <label>Articulo:</label>
            <input
              type="text"
              name="concepto"
              onChange={handleChange}
              value={getValue(product, "concepto")}
              className="form-control bg-light py-2 mb-4"
            />
          </div>
          <div className="col-12 col-md-6">
            <label>Cantidad:</label>
            <input
              type="text"
              name="cantidad"
              onChange={handleChange}
              value={getValue(product, "cantidad")}
              className="form-control bg-light py-2 mb-4"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <label>Unidad:</label>
          <input
            type="text"
            name="unidad"
            onChange={handleChange}
            value={getValue(product, "unidad")}
            className="form-control bg-light py-2 mb-4"
          />
        </div>
        <div className="col-12 col-md-6">
          {" "}
          <label>Precio Unitario ($ MXN):</label>
          <input
            type="number"
            name="precio_unitario"
            onChange={handleChange}
            value={getValue(product, "precio_unitario")}
            className="form-control bg-light py-2 mb-4"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <label>Mínimo:</label>
          <input
            type="number"
            name="minimo"
            onChange={handleChange}
            value={getValue(product, "minimo")}
            className="form-control bg-light py-2 mb-4"
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Máximo:</label>
          <input
            type="number"
            name="maximo"
            onChange={handleChange}
            value={getValue(product, "maximo")}
            className="form-control bg-light py-2 mb-4"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            className="btn text-muted w-100"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </div>
        <div className="col-6">
          <button
            className="btn btn-primary w-100"
            disabled={skuError}
            type="submit"
          >
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProductForm;
