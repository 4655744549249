import React, { Fragment, useContext, useState } from "react";
import ConceptoForm from "./ConceptoForm";
import ConceptoPreciario from "./ConceptoPreciario";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { ConceptosContext } from "../../context/ConceptosContext";
import { PreciarioContext } from "../../context/PreciarioContext";
import { ContratosContext } from "../../context/ContratosContext";
import { ProyectosContext } from "../../context/ProyectosContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import FilaTotales from "./FilaTotales";
import { getValue } from "../../utils";
import usePreciario from "../../hooks/usePreciario";

const PartidaPreciario = ({ partida, showEstimaciones }) => {
  const [showConceptos, toggleConceptos] = useState(false);

  const { user } = useContext(AuthContext);
  const appconfig = useContext(AppConfigContext);
  const { contrato } = useContext(ContratosContext);
  const { proyecto } = useContext(ProyectosContext);
  const { confirm, modalComponent } = useContext(ModalContext);
  const { cancelEdit, editConceptos } = useContext(PreciarioContext);

  const idContrato = contrato !== null ? contrato.idContrato : null;
  const idProyecto = proyecto !== null ? proyecto.idProyecto : null;
  const permiso = proyecto !== null ? proyecto.permiso : user.permiso;

  const {
    conceptos,
    postConceptos,
    createConcepto,
    deleteConcepto,
    setPropiedadConcepto,
  } = useContext(ConceptosContext);
  const { editMode, estimacion } = usePreciario();

  function getConceptos(conceptos, idPartidaContrato) {
    if (conceptos && conceptos !== null)
      return conceptos.filter(
        (concepto) => concepto.idPartidaContrato === idPartidaContrato
      );
    return [];
  }

  function confirmDelete(idConcepto) {
    const concepto = conceptos.find(
      (concepto) => String(concepto.idConcepto) === String(idConcepto)
    );
    if (concepto) {
      confirm(
        `¿Estás seguro que deseas eliminar el concepto ${concepto.nombre}? Esta acción NO puede deshacerse y afectará el avance de la obra.`,
        function () {
          deleteConcepto(idConcepto);
        }
      );
    }
  }

  const renderConceptoPreciario = (concepto) => {
    if (concepto.data) concepto = concepto.data;
    if (!concepto.precio_estimado)
      concepto.precio_estimado = concepto.precio_actual
        ? concepto.precio_actual
        : 0;
    if (!concepto.costo_estimado)
      concepto.costo_estimado = concepto.costo ? concepto.costo : 0;

    if (concepto.subconceptos?.length > 0) {
      return (
        <ConceptoPreciario
          permiso={permiso}
          editMode={editMode}
          concepto={concepto}
          estimacion={estimacion}
          idProyecto={idProyecto}
          key={concepto.idConcepto}
          modalComponent={modalComponent}
          showEstimaciones={showEstimaciones}
          setPropiedadConcepto={setPropiedadConcepto}
        >
          {concepto.subconceptos.map((subconcepto) => {
            return (
              <Fragment key={subconcepto.idSubconcepto}>
                {renderConceptoPreciario(subconcepto)}
              </Fragment>
            );
          })}
        </ConceptoPreciario>
      );
    } else {
      return (
        <ConceptoPreciario
          permiso={permiso}
          editMode={editMode}
          concepto={concepto}
          estimacion={estimacion}
          idProyecto={idProyecto}
          key={concepto.idConcepto}
          modalComponent={modalComponent}
          showEstimaciones={showEstimaciones}
          setPropiedadConcepto={setPropiedadConcepto}
        />
      );
    }
  };

  function renderConceptos(conceptos) {
    if (showConceptos) {
      let components = [];
      if (Array.isArray(conceptos)) {
        conceptos = conceptos.filter(
          (concepto) => String(concepto.idConcepto)[0] !== "d"
        );
        components = conceptos.map((concepto) => {
          if (!concepto.precio_estimado)
            concepto.precio_estimado = concepto.precio_actual;
          if (!concepto.costo_estimado)
            concepto.costo_estimado = concepto.costo;

          if (editMode === `conceptos-${partida.idPartidaContrato}`)
            return (
              <ConceptoForm
                permiso={permiso}
                concepto={concepto}
                idProyecto={idProyecto}
                key={concepto.idConcepto}
                modifier={setPropiedadConcepto}
                deleteConcepto={() => confirmDelete(concepto.idConcepto)}
              />
            );
          return (
            <Fragment key={concepto.idConcepto}>
              {renderConceptoPreciario(concepto)}
            </Fragment>
          );
        });
        if (editMode === null)
          components.push(
            <FilaTotales
              key={`totales-partida-${idPartidaContrato}`}
              showEstimaciones={showEstimaciones}
              conceptos={conceptos}
            />
          );
      }
      components.push(<tr key="editar">{renderBotones()}</tr>);
      return components;
    }
  }

  function renderBotones() {
    if (editMode === `conceptos-${idPartidaContrato}`)
      return (
        <>
          <td />
          <td colSpan={8}>
            <button
              className="btn btn-primary me-2"
              onClick={() =>
                postConceptos(idProyecto, idContrato, conceptos, cancelEdit)
              }
            >
              <i className="fa fa-save"></i> Guardar Conceptos
            </button>
            <button
              className="btn btn-outline-primary me-2"
              onClick={() => createConcepto(idPartidaContrato)}
            >
              <i className="fa fa-plus"></i> Agregar Concepto
            </button>
            <button
              className="btn btn-outline-danger"
              onClick={() => cancelEdit(idPartidaContrato)}
            >
              <i className="fa fa-times"></i> Cancelar
            </button>
          </td>
        </>
      );
    if (editMode === null && ["admin", "coordinador"].includes(permiso))
      return (
        <>
          <td />
          <td colSpan={3}>
            <button
              className="btn btn-outline-primary"
              onClick={() => editConceptos(idPartidaContrato)}
            >
              <i className="fa fa-edit"></i> Editar Conceptos
            </button>
          </td>
        </>
      );
  }

  function renderToggle() {
    return (
      <button
        className="btn btn-link text-secondary py-0 my-0"
        onClick={() => toggleConceptos(!showConceptos)}
      >
        <i className={`fa fa-chevron-${showConceptos ? "up" : "down"}`}></i>
      </button>
    );
  }

  function renderEstimacion() {
    if (String(editMode).includes("conceptos")) {
      if (estimacion === null) {
        return (
          <>
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
            <td />
          </>
        );
      }
      return (
        <>
          <td />
          <td />
          <td />
          {getValue(appconfig, "supervise_estimates", "boolean") && [
            <td key="1" />,
            <td key="2" />,
            <td key="3" />,
          ]}
          {getValue(appconfig, "authorize_estimates", "boolean") && [
            <td key="1" />,
            <td key="2" />,
            <td key="3" />,
            <td key="4" />,
          ]}
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
        </>
      );
    }
    if (showEstimaciones) {
      return (
        <>
          <td />
          <td />
          <td />
          {getValue(appconfig, "supervise_estimates", "boolean") && <td />}
          {getValue(appconfig, "authorize_estimates", "boolean") && <td />}
          <td />
          {/*<td />*/}
          <td />
          <td />
          <td />
          <td />
          <td />
        </>
      );
    }
    return (
      <>
        <td />
        <td />
        <td />
        <td />
        <td />
        {getValue(appconfig, "costs_enabled", "boolean") && <td />}
      </>
    );
  }

  const { clave, nombre, idPartidaContrato } = partida;

  const conceptosPartida = getConceptos(conceptos, idPartidaContrato);

  return (
    <>
      <tr
        className={`partida-preciario ${partida.extra ? "extra" : ""}`}
        onClick={() => toggleConceptos(!showConceptos)}
      >
        <td className="font-weight-bold">{clave}</td>
        <td className="nombre-concepto font-weight-bold">
          {nombre}
          {renderToggle()}
        </td>
        {renderEstimacion()}
      </tr>
      {renderConceptos(conceptosPartida)}
    </>
  );
};

export default PartidaPreciario;
