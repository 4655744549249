import React, { useContext, useEffect, useState } from "react";
import ImageInput from "./common/ImageInput";
import { AuthContext } from "../context/AuthContext";
import { AppConfigContext } from "../context/AppConfigContext";
import { ModalContext } from "../context/ModalContext";

const UserForm = () => {
  const [src, setSrc] = useState("");

  const { BASE_URL } = useContext(AppConfigContext);
  const { clearModal } = useContext(ModalContext);

  const { user, recoverPassword, setPropiedadUser, updateUsuario } =
    useContext(AuthContext);

  useEffect(() => {
    setSrc(BASE_URL + "/adjuntos/" + user.foto);
  }, []);

  return (
    <div className="container">
      <label>Nombre:</label>
      <input
        type="text"
        maxLength={140}
        value={user.nombre}
        className="form-control d-block"
        placeholder="Ejemplo: Eric Vallebueno"
        onChange={(e) => setPropiedadUser("nombre", e.target.value)}
      />

      <button
        className="btn btn-link px-0 py-0 my-3"
        onClick={() => recoverPassword(user.email)}
      >
        Reestablecer contraseña
      </button>

      <ImageInput
        src={src}
        modifier={(file) => setPropiedadUser("picture", file)}
      />

      <div className="row mt-3">
        <div className="col-6 ms-0">
          <button type="button" className="btn text-muted w-100" onClick={clearModal}>
            CANCELAR
          </button>
        </div>
        <div className="col-6 me-0">
          <button
            type="button"
            className="btn btn-primary w-100"
            onClick={() => updateUsuario(user, clearModal)}
          >
            GUARDAR
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
