import React from "react";

const TabGroup = ({ tabs, selected, modifier }) => {
  function toggle(clicked) {
    if (Array.isArray(tabs)) {
      tabs.forEach((tab) => {
        if (clicked === tab.value) tab.selected = true;
        else tab.selected = false;
      });
    }
    if (typeof modifier === "function") {
      modifier(clicked);
    }
  }

  function renderTabs() {
    if (Array.isArray(tabs)) {
      return tabs.map((tab) => (
        <button
          className={`btn btn-${
            selected === tab.value ? "primary" : "outline-primary"
          }`}
          onClick={() => toggle(tab.value)}
        >
          {tab.title}
        </button>
      ));
    }
  }

  return <div className="btn-group w-100">{renderTabs()}</div>;
};

export default TabGroup;
