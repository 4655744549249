import React, { useState } from "react";

const ProductCloneForm = ({ cloneProduct, product_id }) => {
  const [cloneChildren, setCloneChildren] = useState(true);

  const handleCloneToggle = () => {
    setCloneChildren(!cloneChildren);
  };

  const submitProductToClone = () => {
    cloneProduct(product_id, cloneChildren);
  };

  return (
    <div>
      <div className="form-check form-switch">
        <input
          className="form-check-input text-primary"
          type="checkbox"
          role="switch"
          checked={cloneChildren}
          onChange={handleCloneToggle}
        />
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
          Deseas clonar los hijos del producto?
        </label>
      </div>
      <button className="btn btn-primary mt-3" onClick={submitProductToClone}>
        Clonar
      </button>
    </div>
  );
};

export default ProductCloneForm;
