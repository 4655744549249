import React, { useState, useContext, useEffect } from "react";
import { ContratosContext } from "../../context/ContratosContext";
import { ProveedoresContext } from "../../context/ProveedoresContext";
import { ModalContext } from "../../context/ModalContext";
import { getValue } from "../../utils";
import SearchableSelect from "../global/SearchableSelect";

const ContratoForm = ({ idProyecto, hideButtons, handleCallback }) => {
  const [addProveedor, setAddProveedor] = useState(false);
  const { contrato, postContrato, setPropiedadContrato } =
    useContext(ContratosContext);
  const { proveedores, getProveedoresProyecto } =
    useContext(ProveedoresContext);
  const { clearModal } = useContext(ModalContext);

  useEffect(() => {
    getProveedoresProyecto(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  useEffect(() => {
    if (Array.isArray(proveedores)) {
      handleProveedor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proveedores]);

  const handleProveedor = () => {
    if (proveedores.length > 0) {
      if (contrato?.idProveedor === null && contrato.proveedor === "") {
        const current = proveedores[0];
        if (current) setPropiedadContrato("idProveedor", current.idProveedor);
      }
    }
  };

  const handleSubmit = () => {
    postContrato(idProyecto, contrato, handleCallback);
  };

  const renderProveedores = () => {
    return (
      <SearchableSelect
        items={proveedores}
        itemText={"nombre"}
        itemValue={"idProveedor"}
        modifier={(e) => setPropiedadContrato("idProveedor", e.value)}
        value={getValue(contrato, "idProveedor")}
      />
    );
  };

  const renderFormProveedor = () => {
    if (addProveedor) {
      return (
        <input
          type="text"
          value={contrato.proveedor}
          className="form-control mb-3"
          placeholder="Escribe el nombre del proveedor..."
          onChange={(e) => setPropiedadContrato("proveedor", e.target.value)}
        />
      );
    }
    return renderProveedores();
  };

  return (
    <div className="container-fluid px-0">
      <div className="row">
        <div className="col-12 col-md-6">
          <label>Folio</label>
          <input
            type="text"
            className="form-control mb-3"
            value={getValue(contrato, "folio")}
            onChange={(e) => setPropiedadContrato("folio", e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Anticipo (0 - 100%)</label>
          <input
            step="1"
            type="number"
            className="form-control mb-3"
            value={getValue(contrato, "anticipo")}
            onChange={(e) => setPropiedadContrato("anticipo", e.target.value)}
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-6">
          <label>Proveedor</label>
        </div>
        <div className="col-6 text-end">
          <button
            className="btn btn-sm btn-link text-secondary"
            onClick={() => setAddProveedor(!addProveedor)}
          >
            {addProveedor ? "Cancelar" : "Agregar Proveedor"}
          </button>
        </div>
      </div>
      {renderFormProveedor()}
      {!hideButtons && (
        <div className="row mt-3">
          <div className="col-6">
            <button
              className="btn btn-link text-secondary"
              onClick={clearModal}
            >
              Cancelar
            </button>
          </div>
          <div className="col-6">
            <button className="btn btn-primary w-100" onClick={handleSubmit}>
              Guardar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContratoForm;
